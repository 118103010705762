// Util

.no-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Background

.App-header {
  background-color: #282c34;
  display: flex;
  min-height: var(--vh, 100vh);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  video {
    position: fixed;
    object-fit: cover;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  div {
    padding: 2rem;
  }
}

// Main

.App-main {
  position: absolute;
  width: 100vw;
  height: var(--vh, 100vh);
  top: 0;
  left: 0;
}

// Version

.App-version {
  position: absolute;
  bottom: 2rem;
  left: 3rem;
}

// Navigation

.App-navigation {
  position: absolute;
  top: 2.5rem;
  left: 3rem;
  h1 {
    color: rgb(247, 244, 229);
    padding-left: 10px;
    font-size: 4rem;
    &:hover {
      cursor: pointer;
    }
  }
}

.navigation-item-container {
  height: 60px;
  width: 250px;
  position: absolute;
  background-color: transparent;
  border-radius: 2rem;
  h4 {
    position: absolute;
    top: 20px;
    left: 60px;
    font-weight: 900;
    color: transparent;
  }
}

#navigation-item-1,
#navigation-item-2,
#navigation-item-3,
#navigation-item-4 {
  > div > div {
    margin: 0 !important;
    padding: 10px 0 0 10px;
  }
}

svg {
  visibility: hidden;
  &:hover {
    cursor: pointer;
  }
}

// Navigation Icons

.text-pop-up-top {
  -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.text-pop-up-top-reverse {
  -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
  animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
}

@-webkit-keyframes text-pop-up-top {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    text-shadow: none;
  }
  to {
    -webkit-transform: translateY(-0.5rem);
    transform: translateY(-0.5rem);
    text-shadow: 0 1px 0 #85858530, 0 2px 0 #85858530, 0 3px 0 #85858530, 0 4px 0 #85858530, 0 5px 0 #85858530,
                 0 6px 0 #85858530, 0 7px 0 #85858530, 0 8px 0 #85858530, 0 8px 0 #85858530;
  }
}
@keyframes text-pop-up-top {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    text-shadow: none;
  }
  to {
    -webkit-transform: translateY(-0.5rem);
    transform: translateY(-0.5rem);
    text-shadow: 0 1px 0 #85858530, 0 2px 0 #85858530, 0 3px 0 #85858530, 0 4px 0 #85858530, 0 5px 0 #85858530,
                 0 6px 0 #85858530, 0 7px 0 #85858530, 0 8px 0 #85858530, 0 8px 0 #85858530;
  }
}

.flip-in-hor-bottom {
  display: block;
  visibility: visible;
  > div > svg {
    visibility: visible;
    g > path {
      stroke: white;
    }
  }
  margin: 1.5rem 0;
  -webkit-animation: flip-in-hor-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: flip-in-hor-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  &.item-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  &.item-2 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  &.item-3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  &.item-4 {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
}

.flip-in-hor-bottom-reverse {
  display: block;
  visibility: visible;
  > div > svg {
    visibility: visible;
    &:hover {
      cursor: default;
    }
    g > path {
      stroke: white;
    }
  }
  margin: 1.5rem 0;
  -webkit-animation: flip-in-hor-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
  animation: flip-in-hor-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
  &.item-1 {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  &.item-2 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  &.item-3 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  &.item-4 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
}

@-webkit-keyframes flip-in-hor-bottom {
  from {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  to {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  from {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  to {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

.fade-in {
  background-color: rgba(255, 255, 255, 0.5);
  h4 {
    color: black;
  }
  -webkit-animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-out {
  background-color: rgba(255, 255, 255, 0.5);
  h4 {
    color: black;
  }
  -webkit-animation: fade-in 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) reverse both;
  animation: fade-in 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) reverse both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}